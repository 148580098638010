<template>
    <div class="err">
        系统认证失败, 无法访问
    </div>
</template>

<script>
export default {

}
</script>

<style>
.err {
    line-height: 100px;
    font-size: 42px;
    font-weight: 700;
    text-align: center;
}
</style>